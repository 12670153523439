import React from 'react';
import styled from 'styled-components';
import { StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { sortArray } from 'magmel';

// components
import { Navbar, Banner, Footer } from 'src/components/common';
import { SectionHeader, ShootPage } from 'src/components/Photography';

const PortraitsPage = () => {
  return (
    <>
      <ShootPage
        style={{
          marginTop: '100px',
        }}
      >
        <Navbar dark />
        <div className="container-lg">
          <SectionHeader sectionName="Portraits" />
        </div>

        <Grid />
        {/* <div className="write-up container-lg">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus ipsa repudiandae
            nemo cumque facilis minima, numquam, molestiae perferendis laboriosam eaque vel non
            quaerat tempore iure vitae. Quidem autem sit ducimus.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis, cum. Debitis,
            blanditiis voluptatem placeat unde fugiat laudantium cum ratione velit, ab enim
            repudiandae. Esse consequatur, reiciendis animi delectus at magni!
          </p>
        </div> */}
      </ShootPage>

      <Banner />
      <Footer />
    </>
  );
};

const Grid = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { relativePath: { regex: "/photography/portraits/" } }) {
            nodes {
              name
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                  originalImg
                }
              }
            }
          }
        }
      `}
      render={({ allFile }) => {
        const sortedArray = sortArray({
          array: allFile.nodes,
          order: 'asce',
          key: 'name',
        });

        return (
          <div className="horizontal-grid">
            <a class="right-arrow">
              <svg width={25} height={25} viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48px"
                  d="M268 112L412 256 268 400"
                />
                <path
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48px"
                  d="M392 256L100 256"
                />
              </svg>
            </a>
            <div className="horizontal-full hs portriats">
              {sortedArray.map((file, index) => {
                return (
                  <Img
                    className="horizontal-image"
                    fluid={file.childImageSharp.fluid}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
};

const StyledSubSectionLayout = styled.section`
  min-height: 100vh;

  .listing {
    margin-bottom: 200px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, calc(100% / 2));
    grid-template-rows: repeat(2, auto);
    grid-gap: 10px;

    .list-middle-item {
      grid-column-start: span 2;
    }

    .list-item {
      height: 530px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url('https://res.cloudinary.com/beautiful-stories/image/upload/v1589881722/events/IMG_7420_qfqi8k.jpg');
      background-size: contain;
      box-shadow: 0 30px 60px rgba(0, 0, 0, 0.12);

      :hover {
        filter: brightness(87.5%);
      }

      h2 {
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        font-size: 2rem;
        padding: 0 20px;
        background: rgba(0, 0, 0, 0.5);

        /* @supports (-webkit-text-stroke: 1px black) {
          -webkit-text-stroke: 1px black;
          -webkit-text-fill-color: white;
          color: transparent;
        } */
      }
    }
  }
`;

export default PortraitsPage;
